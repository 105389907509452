// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-authenticate-tsx": () => import("./../src/templates/authenticate.tsx" /* webpackChunkName: "component---src-templates-authenticate-tsx" */),
  "component---src-templates-boiler-service-tsx": () => import("./../src/templates/boiler-service.tsx" /* webpackChunkName: "component---src-templates-boiler-service-tsx" */)
}

