module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://dcd679f2eda94b5781c1994379b431b1@o256641.ingest.sentry.io/5259390","environment":"staging","enabled":true,"release":"84c12e16f8ffb6c74781ef4c39096d5e3d6943e7"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"kantan-booking-portal","short_name":"booking","start_url":"/"},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"CfkU2FryZFSTvCA52Fptb5YorNTIStNZ","devKey":"CfkU2FryZFSTvCA52Fptb5YorNTIStNZ"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
